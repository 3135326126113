import { sub, add, differenceInYears } from "date-fns";

import ApiConstants from "../constants/ApiConstants";
import {
  APP_NAME,
  CONTACT_ADDRESS_EMPTY_STATE,
  DEFAULT_ACCOUNTS_FOR_BOOKS_CONTACT,
  DEFAULT_CURRENCY_CODE,
  DEFAULT_GST_TREATMENT,
  DOC_TYPE,
  EMAIL_PAYLOAD,
  EXPORT_ERROR,
  EXPORTED_FILES_TYPE_EXTENSION,
  IN_COUNTRY_CODE,
  NON_GST_TREATMENT_VALUES,
  PRINT_PAYLOAD_KEYS
} from "../constants/Constant";
import { COLUMN_CODE, TableManger, TABLES } from "../managers/TableManger";
import UserManager from "../managers/UserManager";
import {
  IContactAddress,
  IBooksContact,
  ISavedMailTemplate
} from "../model/BooksContact";
import { IQuotation } from "../model/Quotation";
import { store } from "../redux/store";
import Utility, { getValue } from "../utility/Utility";
import httpInstance from "../http";
import { setTenantFinanceDetails } from "../redux/slices/booksSlice";
import { BOOKS_APP_NAME } from "./common/print";
import { PrintDocument } from "../model/PrintDocument";
import {
  COMPLIANCE_SPECIFIC_FIELD_NAME,
  TAX_RESIDENCY
} from "../constants/Enum";
import TenantManager from "../managers/TenantManager";
import { COUNTRY_CODE, COUNTRY_NAME } from "../constants/CountriesAndStates";
import Table from "./table";
import { AxiosResponse } from "axios";
import { showAlert, removeLoader, showLoader } from "deskera-ui-library";
import RouteManager from "../managers/RouteManager";
import { getCountryNameByCountryCode } from "../constants/Currencies";
import { PriceBookManager } from "../components/price_book/PriceBookManager";

export const DEFAULT_PAGE_SIZE = 20;
/**
 * @description: a default financial start date in MM-DD
 */
export const DEFAULT_FINANCIAL_START_DATE = 1;
export const DEFAULT_FINANCIAL_START_MONTH = 1;
export default class BooksService {
  static uomApiConfig = {
    SearchTerm: "",
    Limit: 500,
    Page: 0,
    Query: "",
    QueryParam: "",
    QuerySummary: ""
  };
  static getProducts(params: any = {}) {
    let countryCode = "";
    if (BooksService.getTenantCountry() === IN_COUNTRY_CODE) {
      countryCode = "in";
    }
    return httpInstance.get(ApiConstants.URL.BOOKS.GET_PRODUCTS + countryCode, {
      params
    });
  }
  static async getProductsByDocSeqCode(seqCodes: string[]) {
    if (Utility.isEmptyObject(seqCodes)) return Promise.resolve([]);

    let countryCode = "";
    if (BooksService.getTenantCountry() === IN_COUNTRY_CODE) {
      countryCode = "in";
    }

    return httpInstance.post(
      ApiConstants.URL.PRODUCT.GET_PRODUCTS_BY_SEQ_CODE(countryCode),
      seqCodes,
      { params: { limit: seqCodes.length } }
    );
  }
  static getProductsByPriceBookId(params: any = {}, priceListId) {
    let countryCode = "";
    if (BooksService.getTenantCountry() === IN_COUNTRY_CODE) {
      countryCode = "in";
    }
    return httpInstance.get(
      ApiConstants.URL.BOOKS.GET_PRODUCTS_BY_PRICE_BOOK_ID(
        countryCode,
        priceListId
      ),
      { params }
    );
  }
  static getTaxes(params = {}) {
    params = {
      ...params,
      limit: 100,
      status: "active",
      page: 0,
      skipInterceptor: true
    };
    return httpInstance.get(ApiConstants.URL.BOOKS.GET_TAXES, { params });
  }

  static fetchQuotes = (params) => {
    params = {
      limit: DEFAULT_PAGE_SIZE,
      page: 0,
      fetchAttachmentDetails: true,
      appName: APP_NAME,
      ...params
    };
    return httpInstance.get(ApiConstants.URL.BOOKS.GET_QUOTES, { params });
  };

  static fetchTaxAccount = (params) => {
    return httpInstance.post(ApiConstants.URL.BOOKS.POST_TAX(params), [
      "Bank",
      "Cash",
      "Current Assets",
      "Current Liabilities"
    ]);
  };

  static createTax = (payload) => {
    return httpInstance.post(ApiConstants.URL.BOOKS.CREATE_TAX, payload);
  };

  static createProduct = (payload) => {
    return httpInstance.post(ApiConstants.URL.BOOKS.CREATE_PRODUCT, payload, {
      params: { skipInterceptor: true }
    });
  };

  static fetchTenantsInfo = (tenantID) => {
    return httpInstance
      .get(ApiConstants.URL.BOOKS.GET_TENANT_SETTINGS(tenantID), {
        params: { skipInterceptor: true }
      })
      .then((response) => {
        store.dispatch(setTenantFinanceDetails(false));
        return response;
      })
      .catch((error) => {
        // if (error?.response?.data?.errorMessage === "Tenant FINANCE details not found.") {
        store.dispatch(setTenantFinanceDetails(true));
        // }
        return error;
      });
  };

  static createContact = (payload: IBooksContact) => {
    return httpInstance.post(
      ApiConstants.URL.BOOKS.CREATE_CONTACT(
        TAX_RESIDENCY[UserManager.getTaxResidency()] || ""
      ),
      payload
    );
  };
  static updateContact = (payload: IBooksContact) => {
    return httpInstance.put(
      ApiConstants.URL.BOOKS.UPDATE_CONTACT(
        payload.id,
        TAX_RESIDENCY[UserManager.getTaxResidency()] || ""
      ),
      payload
    );
  };
  static fetchContactByID = (contactID: number) => {
    return httpInstance.get(
      ApiConstants.URL.BOOKS.FETCH_CONTACT_BY_ID(
        contactID,
        TAX_RESIDENCY[UserManager.getTaxResidency()] || ""
      ),
      { params: { skipInterceptor: true } }
    );
  };
  static fetchContactCodeByIds = (payload: any) => {
    return httpInstance.post(
      ApiConstants.URL.BOOKS.FETCH_CONTACT_CODE_BY_IDS,
      payload
    );
  };
  static fetchCRMContactByBooksID = (booksContactCode: number) => {
    const contactTableID = TableManger.getTableId(TABLES.CONTACT);
    const contactsBooksColumnId = TableManger.getColumnId(
      TABLES.CONTACT,
      COLUMN_CODE.CONTACT.BOOKS_CONTACT_ID
    );
    const filterPayload = {
      logicalOperator: "and",
      conditions: [
        {
          colId: contactsBooksColumnId,
          opr: "eq",
          value: booksContactCode
        }
      ]
    };
    return httpInstance.post(
      ApiConstants.URL.TABLE.GET_RECORD_BY_PAGE(contactTableID),
      filterPayload,
      { params: { skipInterceptor: true } }
    );
  };
  static showNoAccessAlert = () => {
    const buttons = [
      {
        title: "Ok",
        className: "bg-gray1 border-m",
        onClick: () => RouteManager.navigateToHome()
      }
    ];
    let message = `You don't have permission to access this module. Please contact your organization owner to provide access.`;
    if (UserManager.isUserOwner()) {
      message = `You don't have permission to access this module. Go to user management to provide access.`;
      buttons.unshift({
        title: "Allow Access",
        className: "bg-button text-white ml-r",
        onClick: () => {
          Utility.openInNewTab(ApiConstants.PRODUCT_URL_GO + "users");
          RouteManager.navigateToHome();
        }
      });
    }
    showAlert("No Access", message, buttons);
  };
  static createQuote = (payload) => {
    return httpInstance
      .post(
        ApiConstants.URL.BOOKS.CREATE_QUOTE(
          TAX_RESIDENCY[UserManager.getTaxResidency()] || ""
        ),
        payload
      )
      .catch((err) => {
        if (err.response?.code === 403 || err.code === 403) {
          BooksService.showNoAccessAlert();
        }
        return Promise.reject(err);
      });
  };
  static updateQuote = (quoteID, payload) => {
    return httpInstance.put(
      ApiConstants.URL.BOOKS.UPDATE_QUOTE(
        quoteID,
        TAX_RESIDENCY[UserManager.getTaxResidency()] || ""
      ),
      payload
    );
  };
  static async patchUpdateQuote(id, payload) {
    return httpInstance
      .patch(ApiConstants.URL.BOOKS.UPDATE_QUOTE_PATCH(id), payload)
      .then((res: any) => Promise.resolve(res))
      .catch((err: any) => {
        console.error("Error while trying to update quote primary", err);
        return Promise.reject(err);
      });
  }
  static populateComplianceFieldsForBooksContact(
    contact: IBooksContact,
    compliance?: { gstIn: string; gstTreatment: string }
  ) {
    if (Utility.isEmptyObject(contact)) return;

    if (BooksService.isIndiaGSTRegistered()) {
      contact.gstin = compliance?.gstIn || "";
      contact.gstTreatment = compliance?.gstTreatment || DEFAULT_GST_TREATMENT;
    }
    if (BooksService.isMYResidency()) {
      contact.exemptionCertificateMalaysia =
        contact.exemptionCertificateMalaysia ?? false;
      contact.customerTypeMalaysia = contact.customerTypeMalaysia ?? "NONE";
      contact.exemptionCriterialMalaysia =
        contact.exemptionCriterialMalaysia ?? "NONE";
      contact.vendorTypeMalaysia = contact.vendorTypeMalaysia ?? "NONE";
    }
  }
  static convertCRMContactToBooks = ({
    crmContact,
    address,
    gstIn,
    gstTreatment
  }) => {
    let booksContact: IBooksContact = {
      name: "",
      payableAccountCode:
        store.getState().books?.tenantsInfo?.financeSettings
          ?.payableAccountCode ||
        DEFAULT_ACCOUNTS_FOR_BOOKS_CONTACT.PAYABLE_ACCOUNT,
      receivableAccountCode:
        store.getState().books?.tenantsInfo?.financeSettings
          ?.receivableAccountCode ||
        DEFAULT_ACCOUNTS_FOR_BOOKS_CONTACT.RECEIVABLE_ACCOUNT,
      paymentTermCode: DEFAULT_ACCOUNTS_FOR_BOOKS_CONTACT.PAYMENT_TERM
    };
    if (!Utility.isEmptyObject(crmContact)) {
      const crmContactColumns = {};
      TableManger.getTableColumns(TABLES.CONTACT).forEach((column) => {
        crmContactColumns[column.columnCode] = column.key;
      });
      booksContact.name =
        crmContact.cells[crmContactColumns[COLUMN_CODE.CONTACT.NAME]];
      booksContact.emailId =
        crmContact.cells[crmContactColumns[COLUMN_CODE.CONTACT.EMAIL]];
      booksContact.contactNumber =
        crmContact.cells[crmContactColumns[COLUMN_CODE.CONTACT.PHONE]];
      booksContact.currencyCode =
        UserManager.getUserCurrency() || DEFAULT_CURRENCY_CODE;

      try {
        const detailedAddressContactColId =
          crmContactColumns[COLUMN_CODE.CONTACT.DETAILED_ADDRESS];
        if (
          Utility.isEmptyObject(detailedAddressContactColId) ||
          Utility.isEmptyObject(crmContact.cells[detailedAddressContactColId])
        ) {
          booksContact.billingAddress = [address];
          booksContact.shippingAddress = booksContact.billingAddress;
        } else {
          booksContact.billingAddress = crmContact.cells[
            detailedAddressContactColId
          ].map((address: IContactAddress) => ({
            ...address,
            preferred: address.billing ?? false,
            contactName: address.contactName || booksContact.name,
            placeOfSupply: null,
            destinationOfSupply: null
          }));
          if (
            !booksContact.billingAddress.some((address) => address.preferred)
          ) {
            booksContact.billingAddress[0].preferred = true;
          }
          booksContact.shippingAddress = crmContact.cells[
            detailedAddressContactColId
          ].map((address: IContactAddress) => ({
            ...address,
            preferred: address.shipping ?? false,
            contactName: address.contactName || booksContact.name,
            placeOfSupply: null,
            destinationOfSupply: null
          }));
          if (
            !booksContact.shippingAddress.some((address) => address.preferred)
          ) {
            booksContact.shippingAddress[0].preferred = true;
          }
        }
      } catch (err) {
        booksContact.billingAddress = [address];
        booksContact.shippingAddress = booksContact.billingAddress;
      }

      const tenantCountry = getCountryNameByCountryCode(
        BooksService.getTenantCountry()
      );
      const convertBlankAddress = (address) => {
        if (
          Utility.isEmptyObject(address) ||
          Utility.isEmptyObject(
            BooksService.getAddressAsString(address as IContactAddress)
          )
        ) {
          return {
            ...CONTACT_ADDRESS_EMPTY_STATE,
            country: tenantCountry
          };
        }

        return address;
      };

      booksContact.billingAddress = (booksContact.billingAddress || []).map(
        convertBlankAddress
      );
      booksContact.shippingAddress = (booksContact.shippingAddress || []).map(
        convertBlankAddress
      );
    }

    BooksService.populateComplianceFieldsForBooksContact(booksContact, {
      gstIn,
      gstTreatment
    });

    return booksContact;
  };
  static convertBooksContactToCRMContact = (booksContact: IBooksContact) => {
    const crmContactColumns = {};
    TableManger.getTableColumns(TABLES.CONTACT).forEach((column) => {
      crmContactColumns[column.columnCode] = column.key;
    });

    let contactAddressList =
      booksContact.billingAddress || booksContact.shippingAddress || [];
    contactAddressList = contactAddressList.map((address, index) => ({
      ...address,
      contactName: address.contactName || booksContact.name,
      full: BooksService.getAddressAsString(address),
      billing: booksContact.billingAddress?.[index]?.preferred ?? false,
      shipping: booksContact.shippingAddress?.[index]?.preferred ?? false
    }));

    const crmContact: any = {
      cells: {
        [crmContactColumns[COLUMN_CODE.CONTACT.NAME]]: booksContact.name,
        [crmContactColumns[COLUMN_CODE.CONTACT.EMAIL]]: booksContact.emailId,
        [crmContactColumns[COLUMN_CODE.CONTACT.PHONE]]:
          booksContact.contactNumber,
        [crmContactColumns[COLUMN_CODE.CONTACT.BOOKS_CONTACT_ID]]:
          booksContact.id
      }
    };

    if (
      !Utility.isEmptyObject(
        crmContactColumns[COLUMN_CODE.CONTACT.DETAILED_ADDRESS]
      )
    ) {
      crmContact.cells[
        crmContactColumns[COLUMN_CODE.CONTACT.DETAILED_ADDRESS]
      ] = contactAddressList;
    }

    return crmContact;
  };
  static convertAccountToContact(account) {
    if (Utility.isEmptyObject(account)) return account;
    account = Utility.deepCloneObject(account);

    const contactColumnCodeToAccountCode = {
      [COLUMN_CODE.CONTACT.NAME]: COLUMN_CODE.ACCOUNT.NAME,
      [COLUMN_CODE.CONTACT.EMAIL]: COLUMN_CODE.ACCOUNT.EMAIL,
      [COLUMN_CODE.CONTACT.PHONE]: COLUMN_CODE.ACCOUNT.PHONE,
      [COLUMN_CODE.CONTACT.ADDRESS]: COLUMN_CODE.ACCOUNT.ADDRESS,
      [COLUMN_CODE.CONTACT.DETAILED_ADDRESS]:
        COLUMN_CODE.ACCOUNT.DETAILED_ADDRESS,
      [COLUMN_CODE.CONTACT.BOOKS_CONTACT_ID]:
        COLUMN_CODE.ACCOUNT.BOOKS_CONTACT_ID
    };

    Object.keys(contactColumnCodeToAccountCode).forEach((contactCode) => {
      const accountCode = contactColumnCodeToAccountCode[contactCode];
      const contactColumnId = TableManger.getColumnId(
        TABLES.CONTACT,
        contactCode
      );
      const accountColumnId = TableManger.getColumnId(
        TABLES.ACCOUNT,
        accountCode
      );

      if (
        Utility.isEmptyObject(contactColumnId) ||
        Utility.isEmptyObject(accountColumnId)
      )
        return;

      account.cells[contactColumnId] = account.cells[accountColumnId];
    });

    account.isAccountContact = true;

    return account;
  }
  static redirectToBooksPlus = (booksContactData) =>
    Utility.openInNewTab(
      `${
        ApiConstants.PRODUCT_URL_BOOKS_PLUS
      }${ApiConstants.URL.BOOKS.CREATE_INVOICE(booksContactData?.id)}`
    );
  static fetchSequenceFormats = (payload: any = {}) => {
    payload = {
      application: payload.application ? payload.application : "ERP",
      module: payload.module ? payload.module : "QUOTE",
      ...payload
    };
    return httpInstance.post(
      ApiConstants.URL.BOOKS.GET_SEQUENCE_FORMAT,
      payload
    );
  };
  static deleteQuote = (quote: IQuotation) => {
    return httpInstance.delete(ApiConstants.URL.BOOKS.DELETE_QUOTE(quote.id));
  };
  static bulkDeleteQuote = (quoteIDs: number[]) => {
    if (Utility.isEmptyObject(quoteIDs)) return Promise.resolve(null);

    return httpInstance.delete(ApiConstants.URL.BOOKS.BULK_DELETE_QUOTE, {
      data: quoteIDs
    });
  };
  static fetchQuoteDetailsByCode = (
    quoteID: string
  ): Promise<AxiosResponse<IQuotation>> => {
    return httpInstance.get(ApiConstants.URL.BOOKS.FETCH_QUOTE_BY_ID(quoteID));
  };
  static getAddressAsString = (
    address: IContactAddress,
    separator = ",",
    excludeContactName = false
  ) => {
    const addressFieldOrder = [
      "address1",
      "address2",
      "city",
      "state",
      "country",
      "postalCode"
    ];

    if (!excludeContactName) {
      addressFieldOrder.unshift("contactName");
    }

    const addressToJoin = Utility.deepCloneObject(address);
    if (Utility.isEmptyObject(addressToJoin)) return "";
    let addressString = "";
    addressFieldOrder.forEach((key) => {
      if (!Utility.isEmptyObject(addressToJoin[key])) {
        addressString += `${addressToJoin[key]}${separator} `;
      }
    });
    return addressString.substring(0, addressString.length - 2);
  };
  static isAddressMissing = (address: IContactAddress, requiredFields = []) => {
    let addressToUpdate: IContactAddress =
      Utility.deepCloneObject(address) || {};
    if (!Utility.isEmptyObject(addressToUpdate?.preferred))
      delete addressToUpdate.preferred;
    return requiredFields.some((key) =>
      Utility.isEmptyObject(addressToUpdate[key])
    );
  };
  static isValidContact = (contact, requiredFields = []) => {
    if (BooksService.isIndiaGSTRegistered()) {
      const isGSTrequired = !NON_GST_TREATMENT_VALUES.includes(
        contact.gstTreatment
      );
      if (isGSTrequired) {
        return (
          !Utility.isEmptyObject(contact.gstIn) &&
          !BooksService.isAddressMissing(contact.address, requiredFields)
        );
      }
    }
    return !BooksService.isAddressMissing(contact.address, requiredFields);
  };
  static getTenantCountry = () => {
    const country = store.getState().books?.tenantsDetails?.country;
    return country;
  };
  static getTenantCurrency = () => {
    const currency = store.getState().books?.tenantsDetails?.currency;
    return currency;
  };
  static getFinancialStartEndDate = (): {
    financialStartDate: Date;
    financialEndDate: Date;
  } => {
    let financialStartDate =
      store.getState().books?.tenantsDetails?.financialStartDate;
    let financialEndDate;

    const currentDate = new Date();
    if (Utility.isEmptyObject(financialStartDate)) {
      let todayDate = new Date();
      todayDate.setDate(DEFAULT_FINANCIAL_START_DATE);
      todayDate.setMonth(DEFAULT_FINANCIAL_START_MONTH);
      todayDate = sub(todayDate, { years: 1 });
      financialStartDate = todayDate;
    } else {
      financialStartDate = new Date(financialStartDate);
      let numberOfYears = differenceInYears(currentDate, financialStartDate);
      financialStartDate.setFullYear(
        financialStartDate.getFullYear() + numberOfYears
      );
    }
    financialEndDate = add(financialStartDate, { years: 1, days: -1 });
    return { financialStartDate, financialEndDate };
  };

  static getFinancialBeginningDate = (): {
    financialBeginningDate: Date;
  } => {
    let financialBeginningDate =
      store.getState().books?.tenantsDetails?.bookBeginningStartDate;

    const currentDate = new Date();
    if (Utility.isEmptyObject(financialBeginningDate)) {
      let todayDate = new Date();
      todayDate.setDate(DEFAULT_FINANCIAL_START_DATE);
      todayDate.setMonth(DEFAULT_FINANCIAL_START_MONTH);
      todayDate = sub(todayDate, { years: 1 });
      financialBeginningDate = todayDate;
    } else {
      financialBeginningDate = new Date(financialBeginningDate);
      let numberOfYears = differenceInYears(
        currentDate,
        financialBeginningDate
      );
      financialBeginningDate.setFullYear(
        financialBeginningDate.getFullYear() + numberOfYears
      );
    }
    return { financialBeginningDate };
  };

  static checkAndReturnIfContactSyncWithBooks = (
    contact: any
  ): Promise<{ isSynced: boolean; contact?: any }> => {
    const booksContactColumnId = TableManger.getColumnId(
      TABLES.CONTACT,
      COLUMN_CODE.CONTACT.BOOKS_CONTACT_ID
    );
    if (contact?.cells[booksContactColumnId] > 0) {
      return BooksService.fetchContactByID(contact?.cells[booksContactColumnId])
        .then((response) => {
          return Promise.resolve({ isSynced: true, contact: response });
        })
        .catch((error) => {
          return Promise.resolve({ isSynced: false });
        });
    } else {
      return Promise.resolve({ isSynced: false });
    }
  };

  static setTemplate(payload: any) {
    const endPoint = ApiConstants.URL.COMMON.ADD_CUSTOM_TEMPLATE;
    return httpInstance.post(endPoint, payload);
  }

  static getSavedEmailTemplate(documentType: any): Promise<ISavedMailTemplate> {
    const endPoint = ApiConstants.URL.COMMON.GET_CUSTOM_TEMPLATE(
      documentType,
      BOOKS_APP_NAME
    );
    return httpInstance.get(endPoint, {
      params: { skipInterceptor: true }
    });
  }

  static sendEmail(
    category: any,
    documentClone: any,
    tenantDetails: any,
    email: any,
    moduleCategory: any,
    sendEmailPayload: any,
    templateId?: string,
    currencyDetails?: any,
    isCustomTemplate?: boolean
  ) {
    const emailRequest = {
      [EMAIL_PAYLOAD.application]: BOOKS_APP_NAME,
      [EMAIL_PAYLOAD.category]: category,
      [EMAIL_PAYLOAD.body]: email.body,
      [EMAIL_PAYLOAD.to]: email.to,
      [EMAIL_PAYLOAD.subject]: email.subject,
      [EMAIL_PAYLOAD.replyTo]: email.replyTo,
      [EMAIL_PAYLOAD.senderName]: email.senderName,
      [EMAIL_PAYLOAD.bcc]: email.bcc,
      [EMAIL_PAYLOAD.cc]: email.cc,
      exportDocumentRequest: BooksService.getPrintObject(
        documentClone,
        tenantDetails,
        moduleCategory,
        templateId,
        currencyDetails,
        isCustomTemplate
      )
    };

    sendEmailPayload.sendEmailRequest = emailRequest;
    return httpInstance.post(
      ApiConstants.URL.BOOKS.SEND_EMAIL_SALES,
      sendEmailPayload
    );
  }
  static getPrintObject(
    document: any,
    _tenantDetails: any,
    _moduleName: any,
    templateId?: string,
    currencyDetails?: any,
    isCustomTemplate = false
  ) {
    let invoicePayLoad = new PrintDocument(document, _tenantDetails) as any;
    const newCurrencyDetails = {
      currency: currencyDetails.currencyCode,
      currencySymbol: currencyDetails.currencySymbol,
      currencyName: currencyDetails.currencyName
    };
    const newPayload = {
      ...invoicePayLoad,
      dueAmount: document.dueAmount,
      ...newCurrencyDetails
    };
    return {
      headers: [newPayload],
      documentNumber: document.documentSequenceCode,
      [PRINT_PAYLOAD_KEYS.module_name]: "ERP",
      [PRINT_PAYLOAD_KEYS.category_name]: _moduleName,
      [PRINT_PAYLOAD_KEYS.template_id]: templateId ? templateId : null,
      [PRINT_PAYLOAD_KEYS.IS_CUSTOM_TEMPLATE]: isCustomTemplate,
      [PRINT_PAYLOAD_KEYS.DOCUMENT_TYPE_KEY]: document.documentType,
      [PRINT_PAYLOAD_KEYS.DOCUMENT_CODE_KEY]: invoicePayLoad.BillNo
    };
  }
  static isIndiaGSTRegistered = () =>
    TAX_RESIDENCY[UserManager.getTaxResidency()] === TAX_RESIDENCY.IN &&
    TenantManager.isGTSRegistered();

  static isMYResidency = () =>
    TAX_RESIDENCY[UserManager.getTaxResidency()] === TAX_RESIDENCY.MY;

  static getRequiredAddressFields = (addressData) => {
    let requiredFields = [];

    if (Utility.isEmptyObject(addressData)) addressData = { country: null };
    const { country } = addressData;
    if (
      store.getState().books?.tenantsDetails?.country === COUNTRY_CODE.INDIA &&
      BooksService.isIndiaGSTRegistered()
    ) {
      requiredFields.push(...["state", "country"]);
    } else if (
      store.getState().books?.tenantsDetails?.country === COUNTRY_CODE.USA &&
      TenantManager.isComplianceEnabled()
    ) {
      requiredFields = ["address1", "postalCode", "country"];
      if (country === COUNTRY_NAME.INDIA) {
        requiredFields.push(...["state", "city"]);
      }
      if (country === COUNTRY_NAME.USA) {
        requiredFields.push(...["state"]);
      }
    }
    return requiredFields;
  };
  static fetchDocumentData = (params, documentType) => {
    params = { ...params };
    return httpInstance.get(
      ApiConstants.URL.BOOKS.GET_DOCUMENT_DATA(documentType),
      { params }
    );
  };
  static async exportFiles(
    format: EXPORTED_FILES_TYPE_EXTENSION,
    module: string
  ) {
    const fileType = format;
    const fileName = `${module.toLowerCase()}.${fileType.toLowerCase()}`;
    const finalEndpoint =
      ApiConstants.URL.EXIM.EXPORT +
      `?format=${format}&module=${module}&isNewUi=true`;
    return httpInstance
      .get(finalEndpoint, {
        responseType: "blob"
      })
      .then((res: any) => {
        import("../components/import_export/utility/ExportData").then(
          ({ triggerDownload }) => triggerDownload(res, fileName)
        );
      })
      .catch((err: any) => {
        console.error("Error while trying to get account: ", err);
        return Promise.reject(err);
      });
  }

  static exportFilesWithFilter(
    format: EXPORTED_FILES_TYPE_EXTENSION,
    module: string,
    filterConfig?: any
  ) {
    const fileType = format;
    const fileName = `${module.toLowerCase()}.${fileType.toLowerCase()}`;
    let finalEndpoint =
      ApiConstants.URL.EXIM.EXPORT +
      `?format=${format}&module=${module}&isNewUi=true`;
    if (filterConfig?.Query) {
      finalEndpoint += `&query=${filterConfig.Query}`;
    }

    if (!Utility.isEmptyObject(filterConfig?.SearchTerm)) {
      finalEndpoint += `&search=${filterConfig?.SearchTerm}`;
    }
    return httpInstance
      .get(finalEndpoint, {
        responseType: "blob"
      })
      .then((res: any) => {
        import("../components/import_export/utility/ExportData").then(
          ({ triggerDownload }) => triggerDownload(res, fileName)
        );
      })
      .catch((err: any) => {
        console.error("Error while trying to get account: ", err);
        if (
          err?.status === EXPORT_ERROR.EXPORT_LOCK_CODE ||
          err?.code === EXPORT_ERROR.EXPORT_LOCK_CODE
        ) {
          removeLoader();
          showAlert("Error", EXPORT_ERROR.EXPORT_LOCK_WARNING_MSG);
        } else {
          return Promise.reject(err);
        }
      });
  }

  static fetchInvoiceSummary = (params) => {
    params = { ...params };
    return httpInstance.get(ApiConstants.URL.BOOKS.GET_INVOICE_SUMMARY, {
      params
    });
  };
  static fetchQuotesByContactIds = (params, contactIds: number[]) => {
    params = { ...params, appName: APP_NAME };
    return httpInstance.post(
      ApiConstants.URL.BOOKS.GET_QUOTE_BY_CONTACT_IDS,
      contactIds,
      { params }
    );
  };
  static fetchQuotesByContactAndDealIds = (params, payload: any) => {
    params = { ...params, appName: APP_NAME };
    return httpInstance.post(
      ApiConstants.URL.BOOKS.GET_QUOTE_BY_RECORD_IDS,
      payload,
      { params }
    );
  };
  static fetchDocumentsByContactIds = (
    params: any,
    documentType: string,
    contactIds: number[]
  ) => {
    params = { ...params };
    return httpInstance.post(
      ApiConstants.URL.BOOKS.GET_DOCUMENT_BY_CONTACT_IDS(documentType),
      contactIds,
      { params }
    );
  };

  /* *************** APPROVAL SERVICES **************** */
  static fetchBooksApprovalFlows() {
    return httpInstance.get(ApiConstants.URL.WORKFLOW.GET_BOOKS_APPROVAL_NODE);
  }

  static sendTriggerOnApproval(payload) {
    const finalEndPoint = ApiConstants.URL.WORKFLOW.QUOTE_TRIGGER;
    return httpInstance.post(finalEndPoint, payload);
  }

  static getApprovalHistory(
    documentSequenceCode: string,
    docType: DOC_TYPE = DOC_TYPE.QUOTE
  ) {
    const draftTableId = TableManger.getTableId(TABLES.DRAFT);
    const payload: any = {
      documentType: docType,
      documentCode: documentSequenceCode
    };
    showLoader();
    return httpInstance
      .post(
        ApiConstants.URL.WORKFLOW.GET_APPROVAL_HISTORY(draftTableId),
        payload,
        { params: { skipInterceptor: true } }
      )
      .then((response: any) => {
        removeLoader();
        return Promise.resolve(response);
      })
      .catch((err: any) => {
        removeLoader();
        console.error("Error fetching history: ", err);
        return Promise.reject(err);
      });
  }

  /* *************** DRAFT SERVICES **************** */
  static getDraftTableId() {
    return TableManger.getTableId(TABLES.DRAFT);
  }

  static async getDraftRecords(
    params = { SearchTerm: "", pageSize: 25, pageNo: 1 },
    filterPayload = {}
  ) {
    return await Table.getRecordsByPage(
      params,
      filterPayload,
      BooksService.getDraftTableId()
    );
  }

  static async getDraftRecordById(recordId: string) {
    return await Table.getRecordById(recordId, BooksService.getDraftTableId());
  }

  static async updateDraftRecord(payload, draftId: string) {
    return Table.updateRecord(payload, draftId, BooksService.getDraftTableId());
  }

  static async createDraftRecord(payload) {
    return await Table.addRecord(payload, BooksService.getDraftTableId());
  }

  static async deleteDraftRecord(recordId: string) {
    return await Table.deleteRecord(recordId, BooksService.getDraftTableId());
  }

  static async bulkDeleteDraftRecords(recordIds: string[]) {
    if (Utility.isEmptyObject(recordIds)) return Promise.resolve(null);

    return await Table.deleteRecordInBulk(
      recordIds,
      BooksService.getDraftTableId()
    );
  }

  static getCustomFields(params) {
    const parsedParams = {
      limit: params?.limit || 1000,
      query: `module=${params?.module}`
    };
    return httpInstance.get(ApiConstants.URL.CUSTOM_FIELDS.GET, {
      params: parsedParams
    });
  }
  static fetchLastPricesOfProduct(
    productCodes: string[],
    documentType: DOC_TYPE,
    contactCode: String
  ): Promise<any> {
    const payload = {
      contactCode,
      documentType,
      numberOfPricesToFetch: 3,
      productCodes
    };
    return httpInstance.post(
      ApiConstants.URL.BOOKS.FETCH_PRODUCTS_UNIT_PRICES,
      payload
    );
  }
  static getPricesFromPriceList(payload: any): Promise<any> {
    return httpInstance.post(ApiConstants.URL.BOOKS.PRICING, payload);
  }
  static getPricesListById(id: any): Promise<any> {
    return httpInstance.get(ApiConstants.URL.PRICE_BOOK.GET_PRICE_LIST_ID(id));
  }
  static getUOMs() {
    const queryEndPoint = `?page=${this.uomApiConfig.Page}&limit=${this.uomApiConfig.Limit}&search=${this.uomApiConfig.SearchTerm}`;
    const endPoint = ApiConstants.URL.BOOKS.FETCH_UOM + queryEndPoint;
    return httpInstance
      .get(endPoint)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  // URL for fetching products and variants but not master product of variants
  static getProductsWithVariantsURL(searchTerm = "", query = "", params = "") {
    searchTerm = searchTerm ? encodeURIComponent(searchTerm) : "";
    let queryString: string = `?search=${searchTerm}&limit=25&query=${query}`;
    if (params) {
      queryString += `&productTransactionType=${params}`;
    }
    const countryCode = PriceBookManager.getTenantSpecificApiCode(
      COMPLIANCE_SPECIFIC_FIELD_NAME.PRODUCT
    );
    const url: string =
      ApiConstants.URL.PRODUCT.GET_PRODUCTS +
      (countryCode !== "" ? `${countryCode}` : "") +
      queryString;
    return url;
  }

  static async fetchAllAdditionalCharge() {
    return httpInstance
      .get(ApiConstants.URL.BOOKS.ADDITIONAL_COST.GET)
      .then((res) => Promise.resolve(res))
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static async createAdditionalCharge(req: any) {
    return httpInstance
      .post(ApiConstants.URL.BOOKS.ADDITIONAL_COST.POST, req)
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }
  static async updateAdditionalCharge(req: any) {
    return httpInstance
      .put(ApiConstants.URL.BOOKS.ADDITIONAL_COST.PUT(req.id), req)
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }
  static async deleteAdditionalCharge(req: any) {
    return httpInstance
      .delete(ApiConstants.URL.BOOKS.ADDITIONAL_COST.DELETE(req.id))
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static async calculateUsTax(payload: any): Promise<any> {
    return httpInstance.post(ApiConstants.URL.BOOKS.CALCULATE_US_TAX, payload);
  }

  static async fetchAccountByCode(accountCode: string) {
    return httpInstance.get(ApiConstants.URL.BOOKS.ACCOUNTS.FETCH_ACCOUNTS, {
      params: { query: `code=${accountCode}` }
    });
  }

  static async fetchHSNByCode(code: string) {
    return httpInstance.get(ApiConstants.URL.BOOKS.ACCOUNTS.HSN, {
      params: { query: `code=${code}` }
    });
  }

  static async fetchSACByCode(code: string) {
    return httpInstance.get(ApiConstants.URL.BOOKS.ACCOUNTS.SAC, {
      params: { query: `code=${code}` }
    });
  }
}
export const getUpdatedQuoteObject = (props) => {
  let updatedObj = {
    ...props,
    documentType: DOC_TYPE.QUOTE,
    fulfillmentStatus: props.fulfillmentStatus || "UNFULFILLED",
    fulfillmentType: props.fulfillmentType,
    documentCode: getValue(props.documentCode, props.quotationCode),
    fulfillmentDate: getValue(props.fulfillmentDate, props.shipByDate),
    status: !props.id ? "OPEN" : props.status,
    backOrder: props.backOrder ? props.backOrder : false,

    items: props.quotationItemDtoList?.map((item) => {
      return {
        ...item,
        unitPriceGstInclusive: props.unitPriceGstInclusive,
        exchangeRate: props.exchangeRate,
        documentItemCode: getValue(
          item.documentItemCode,
          item.quotationItemCode,
          item.salesInvoiceItemCode
        )
      };
    }),
    linkedSalesInvoices: props.linkedSalesInvoices,
    processedInPPS: props.processedInPPS,
    reservedStock: props.reservedStock ? props.reservedStock : false
  };

  return updatedObj;
};
export const getUpdatedInvoiceObject = (props) => {
  let updatedObj = {
    ...props,
    documentType: DOC_TYPE.SALES_INVOICE,
    currencyCode: props.currency,
    documentCode: getValue(props.documentCode, props.salesInvoiceCode),
    openingInvoice: props.openingInvoice || false,
    fulfillmentStatus: props.fulfillmentStatus || "UNFULFILLED",
    fulfillmentType: props.fulfillmentType,
    autoFulfill: props.autoFulfill,
    documentDate: getValue(props.documentDate, props.salesInvoiceDate),
    validTillDate: getValue(props.validTillDate, props.salesInvoiceDueDate),
    fulfillmentDate: getValue(props.fulfillmentDate, props.shipByDate),
    status: !props.id ? "OPEN" : props.status,
    backOrder: props.backOrder ? props.backOrder : false,
    paymentStatus: props.paymentStatus,
    items: props.salesInvoiceItems?.map((item: any) => {
      return {
        ...item,
        unitPriceGstInclusive: props.unitPriceGstInclusive,
        exchangeRate: props.exchangeRate,
        documentItemCode: getValue(
          item.documentItemCode,
          item.quotationItemCode,
          item.salesInvoiceItemCode
        )
      };
    }),
    taxInvoiceNo: props.taxInvoiceNo,
    whtRate: props.whtRate && props.whtRate !== null ? props.whtRate : 0,
    paymentInformation: props.paymentInformation
      ? props.paymentInformation
      : null,
    einvoiceInfoIndia: props.einvoiceInfoIndia,
    einvoiceInfoIndiaCancel: props.einvoiceInfoIndiaCancel,
    isCancelEinvoice: props.isCancelEinvoice,
    reservedStock: props.reservedStock ? props.reservedStock : false
  };

  return updatedObj;
};
