import { PRODUCT_DEFAULT_MOQ } from "../../constants/Constant";
import { APPROVAL_STATUS, GST_TYPE } from "../../constants/Enum";
import TenantManager from "../../managers/TenantManager";
import {
  IAdditionalCharges,
  IMultiApprovalDetails,
  IQuotation
} from "../../model/Quotation";
import { store } from "../../redux/store";
import BooksService from "../../services/books";
import { roundOff, roundOffToTenantDecimalScale } from "../../utility/Math";
import Utility from "../../utility/Utility";

export const CESS_RULE_AMOUNT = "amount";
export const CESS_RULE_QUANTITY = "quantity";
export interface ILineItem {
  product?: any;
  productDescription?: string;
  productCode?: string;
  productQuantity?: number;
  unitPrice?: number;
  unitDiscount?: number;
  discount?: number;
  isDiscountInPercentage?: boolean;
  tax?: any;
  taxCode?: string;
  subTotal?: number;
  currency?: string;
  totalAmount?: number;
  unitOfMeasure?: any;
  uom?: any;
  documentUom?: any;
  invalidFields?: string[];
  taxAmount?: number;
  discountAmount?: number;
  lineNumber?: number;
  stockUom?: number;
  documentUOMSchemaDefinition?: any;
  uomUnitPrice?: any;
  uomQuantity?: any;
  customField?: any;
  nonEditableColumns?: string[];
  taxPreference?: any;
  taxExemptionReason?: any;
  compositionTaxPercent?: any;
  cgstRate?: number;
  sgstRate?: number;
  igstRate?: number;
  rcmRate?: number;
  igstAmount?: number;
  cgstAmount?: number;
  sgstAmount?: number;
  cessAmount?: number;
  cessRule?: any;
  productGroupId?: any;
  productGroupName?: string;
  firstAmountChangeDone?: boolean;
  gstType?: GST_TYPE;
  userSetTaxes?: any;
  totalWithDiscount?: number;
  unitPriceGstInclusive?: boolean;
  upliftPrice?: any;
  downliftPrice?: any;
  basePrice?: any;
  documentSequenceCode?: any;
  optional?: any;
  productGroupUuid?: any;
  productGroupIndex?: number;
  nonGlobalProduct?: boolean;
  minimumQuantity?: number;
}
export default class LineItem {
  static lineItem: ILineItem = {
    product: null,
    productDescription: null,
    productQuantity: null,
    unitPrice: null,
    unitDiscount: null,
    discount: null,
    isDiscountInPercentage: null,
    tax: null,
    totalAmount: null,
    currency: null,
    unitOfMeasure: null,
    uom: null,
    uomUnitPrice: null,
    igstAmount: 0,
    cgstAmount: 0,
    sgstAmount: 0,
    cessAmount: 0,
    cessRule: null,
    compositionTaxPercent: null,
    uomQuantity: null,
    documentUOMSchemaDefinition: null,
    userSetTaxes: false,
    unitPriceGstInclusive: false,
    upliftPrice: 0,
    downliftPrice: 0,
    basePrice: 0,
    documentSequenceCode: null,
    optional: null,
    productGroupUuid: null,
    productGroupIndex: null,
    minimumQuantity: PRODUCT_DEFAULT_MOQ,
    nonGlobalProduct: false
  };
  constructor(lineItem: ILineItem) {
    LineItem.lineItem = lineItem;
  }

  static tenantInfo: any = store.getState().books.tenantsDetails;

  static calculateIndiaTax = (total?: number) => {
    LineItem.lineItem.cgstRate = 0;
    LineItem.lineItem.sgstRate = 0;
    LineItem.lineItem.igstRate = 0;
    LineItem.lineItem.cessAmount = 0;
    switch (LineItem.lineItem.gstType) {
      case GST_TYPE.INTER:
        LineItem.lineItem.igstRate =
          LineItem.lineItem.compositionTaxPercent &&
          LineItem.lineItem.compositionTaxPercent > 0
            ? LineItem.lineItem.compositionTaxPercent
            : LineItem.lineItem.tax && LineItem.lineItem.tax.percent
              ? LineItem.lineItem.tax.percent
              : 0;
        LineItem.lineItem.rcmRate = LineItem.lineItem.igstRate;
        break;
      case GST_TYPE.INTRA:
        const halfGstRate =
          (LineItem.lineItem.compositionTaxPercent &&
          LineItem.lineItem.compositionTaxPercent > 0
            ? LineItem.lineItem.compositionTaxPercent
            : LineItem.lineItem.tax && LineItem.lineItem.tax.percent
              ? LineItem.lineItem.tax.percent
              : 0) / 2;
        LineItem.lineItem.cgstRate = halfGstRate;
        LineItem.lineItem.sgstRate = halfGstRate;
        LineItem.lineItem.rcmRate =
          LineItem.lineItem.cgstRate + LineItem.lineItem.sgstRate;
        break;
      case GST_TYPE.EXEMPT:
        LineItem.lineItem.cgstRate = 0;
        LineItem.lineItem.sgstRate = 0;
        LineItem.lineItem.igstRate = 0;
        LineItem.lineItem.cessAmount = 0;
        break;
    }
    // LineItem.lineItem.igstAmount = roundOffToTenantDecimalScale(total * (LineItem.lineItem.igstRate / 100));
    // LineItem.lineItem.cgstAmount = roundOffToTenantDecimalScale(total * (LineItem.lineItem.cgstRate / 100));
    // LineItem.lineItem.sgstAmount = roundOffToTenantDecimalScale(total * (LineItem.lineItem.sgstRate / 100));
    // LineItem.lineItem.cessAmount = roundOffToTenantDecimalScale(total * (LineItem.lineItem.cessAmount / 100));

    if (LineItem.lineItem.userSetTaxes) {
      LineItem.lineItem.igstAmount = LineItem.lineItem.igstAmount || 0;
    } else {
      LineItem.lineItem.igstAmount = LineItem.lineItem.igstRate
        ? LineItem.getTaxInPercentForCalculation(LineItem.lineItem.igstRate)
        : 0;
    }

    if (LineItem.lineItem.unitPriceGstInclusive) {
      let totalAfterDiscount =
        roundOffToTenantDecimalScale(LineItem.lineItem.subTotal || 0) -
        (LineItem.lineItem.discountAmount
          ? LineItem.lineItem.discountAmount
          : 0);

      let totalGSTTax = 0;

      if (LineItem.lineItem.userSetTaxes) {
        if (LineItem.lineItem?.gstType === GST_TYPE.INTER) {
          totalGSTTax = LineItem.lineItem.igstAmount || 0;
        }
        if (LineItem.lineItem?.gstType === GST_TYPE.INTRA) {
          totalGSTTax =
            (LineItem.lineItem?.cgstAmount || 0) +
            (LineItem.lineItem?.sgstAmount || 0);
        }
      } else {
        totalGSTTax = roundOffToTenantDecimalScale(
          (totalAfterDiscount *
            (LineItem.lineItem?.gstType !== GST_TYPE.EXEMPT &&
            LineItem.lineItem?.tax?.percent
              ? LineItem.lineItem?.tax?.percent
              : 0)) /
            100
        );
      }

      LineItem.lineItem.cgstAmount =
        LineItem.lineItem.cgstRate && LineItem.lineItem.cgstRate > 0
          ? roundOffToTenantDecimalScale(totalGSTTax / 2)
          : 0;
      LineItem.lineItem.sgstAmount = LineItem.lineItem.cgstAmount;

      LineItem.lineItem.igstAmount =
        LineItem.lineItem.igstRate && LineItem.lineItem.igstRate > 0
          ? roundOffToTenantDecimalScale(totalGSTTax)
          : 0;
      LineItem.lineItem.cessAmount = !LineItem.lineItem.userSetTaxes
        ? LineItem.calculateCessAmount()
        : LineItem.lineItem.cessAmount;
    } else {
      LineItem.lineItem.cgstAmount =
        LineItem.lineItem.cgstRate && LineItem.lineItem.cgstRate > 0
          ? Utility.roundingOff(
              ((LineItem.getTotalWithDiscount()
                ? LineItem.getTotalWithDiscount()
                : 0) *
                (LineItem.lineItem.tax && LineItem.lineItem.tax.percent
                  ? LineItem.lineItem.tax.percent
                  : 0)) /
                100,
              LineItem.tenantInfo.decimalScale
                ? LineItem.tenantInfo.decimalScale
                : 2
            ) / 2
          : 0;
      LineItem.lineItem.sgstAmount = LineItem.lineItem.cgstAmount;

      LineItem.lineItem.cessAmount = !LineItem.lineItem.userSetTaxes
        ? LineItem.calculateCessAmount()
        : LineItem.lineItem.cessAmount;
    }
    return (
      roundOffToTenantDecimalScale(LineItem.lineItem.cgstAmount) +
      roundOffToTenantDecimalScale(LineItem.lineItem.sgstAmount) +
      (LineItem.lineItem.igstAmount || 0) +
      (LineItem.lineItem.cessAmount ? LineItem.lineItem.cessAmount : 0)
    );
  };
  static getTotalWithDiscount() {
    const exciseAmount = 0;
    LineItem.lineItem.totalWithDiscount =
      typeof LineItem.lineItem.subTotal === "number" &&
      typeof LineItem.lineItem.discountAmount === "number"
        ? LineItem.lineItem.subTotal +
          exciseAmount -
          LineItem.lineItem.discountAmount
        : 0;
    return (LineItem.lineItem.totalWithDiscount = roundOffToTenantDecimalScale(
      LineItem.lineItem.totalWithDiscount
    ));
  }

  static getTaxInPercentForCalculation(percent: number) {
    return percent
      ? roundOff(
          (LineItem.getTotalWithDiscount()
            ? LineItem.getTotalWithDiscount() * percent
            : 0 * percent) / 100
        )
      : 0;
  }

  static calculateCessAmount(totalAfterDiscount?: number) {
    let cessAmount = 0;
    const taxableAmount =
      typeof totalAfterDiscount === "number"
        ? totalAfterDiscount
        : LineItem.getTotalWithDiscount();
    if (LineItem.lineItem.cessRule && taxableAmount) {
      let cessExpression = "";
      if (LineItem.lineItem.productQuantity) {
        cessExpression = LineItem.lineItem.cessRule.replace(
          CESS_RULE_QUANTITY,
          `${LineItem.lineItem.productQuantity}`
        );
      }

      if (taxableAmount) {
        cessExpression = cessExpression
          ? cessExpression.replace(CESS_RULE_AMOUNT, `${taxableAmount}`)
          : LineItem.lineItem.cessRule.replace(
              CESS_RULE_AMOUNT,
              `${taxableAmount}`
            );
      }
      if (cessExpression) {
        cessAmount = eval(cessExpression);
      }
    }
    return roundOff(cessAmount);
  }
  static calculateTotal() {
    let {
      product,
      productQuantity,
      unitPrice,
      discount,
      unitDiscount,
      isDiscountInPercentage,
      tax,
      taxAmount
    } = LineItem.lineItem;
    let total = 0;
    if (product && productQuantity && unitPrice) {
      total = roundOffToTenantDecimalScale(
        (productQuantity || 0) * (unitPrice || 0)
      );
      LineItem.lineItem.subTotal = total;
      if (typeof unitDiscount === "number") {
        discount = isDiscountInPercentage
          ? unitDiscount
          : Utility.roundingOff(productQuantity * unitDiscount);
        LineItem.lineItem.discount = discount;
      }
      if (discount) {
        if (isDiscountInPercentage) {
          LineItem.lineItem.discountAmount = roundOffToTenantDecimalScale(
            total * (discount / 100)
          );
          total = roundOffToTenantDecimalScale(
            total - (total * discount) / 100
          );
        } else {
          total = roundOffToTenantDecimalScale(total - discount);
          LineItem.lineItem.discountAmount = discount;
        }
      } else {
        LineItem.lineItem.discountAmount = 0;
      }
      if (BooksService.getTenantCountry() === "US") {
        let taxAmountToAdd = taxAmount || 0;
        LineItem.lineItem.taxAmount = taxAmountToAdd;
        total = roundOffToTenantDecimalScale(total + taxAmountToAdd);
      } else {
        if (!Utility.isEmptyObject(tax)) {
          LineItem.lineItem.taxAmount = roundOffToTenantDecimalScale(
            total * (tax.percent / 100)
          );
          if (
            !tax?.isTaxGroup &&
            BooksService.getTenantCountry() === "IN" &&
            TenantManager.isGTSRegistered()
          ) {
            LineItem.lineItem.taxAmount = this.calculateIndiaTax();
          }
          total = roundOffToTenantDecimalScale(
            total +
              total * (tax.percent / 100) +
              (LineItem.lineItem.cessAmount || 0)
          );
        }
      }
    }
    // Removed negative price check for total amount for ZEN-12270
    // if (total < 0) {
    //   LineItem.lineItem.invalidFields = LineItem.lineItem?.invalidFields ? LineItem.lineItem?.invalidFields?.filter(colKey => colKey !== 'totalAmount') : [];
    //   LineItem.lineItem.invalidFields.push('totalAmount');
    // } else {
    //   LineItem.lineItem.invalidFields = LineItem.lineItem.invalidFields ? LineItem.lineItem.invalidFields.filter(colKey => colKey !== 'totalAmount') : [];
    // }
    LineItem.lineItem.totalAmount = total;
  }
  static calculateSubTotal = (lineItems: ILineItem[]) =>
    this.filterOptionalProducts(lineItems)?.reduce((acc, lineItem) => {
      const productQuantity = Number(lineItem.productQuantity);
      const unitPrice = Number(lineItem.unitPrice);
      return roundOffToTenantDecimalScale(acc + productQuantity * unitPrice);
    }, 0);
  static calculateDiscountAmount = (lineItems: ILineItem[]) =>
    this.filterOptionalProducts(lineItems)?.reduce((acc, lineItem) => {
      const discountAmount = Number(lineItem.discountAmount);
      return roundOffToTenantDecimalScale(acc + (discountAmount || 0));
    }, 0);
  static calculateTaxAmount = (lineItems: ILineItem[]) =>
    this.filterOptionalProducts(lineItems)?.reduce((acc, lineItem) => {
      const taxAmount = Number(lineItem.taxAmount);
      return roundOffToTenantDecimalScale(acc + (taxAmount || 0));
    }, 0);
  static calculateTotalAmount = (lineItems: ILineItem[]) =>
    this.filterOptionalProducts(lineItems)?.reduce((acc, lineItem) => {
      const totalAmount = Number(lineItem.totalAmount);
      return roundOffToTenantDecimalScale(acc + (totalAmount || 0));
    }, 0);
  static calculateTotalWithAdditionalCharges = (
    lineItems: ILineItem[],
    quote: IQuotation | IQuoteDocument
  ) => {
    return roundOffToTenantDecimalScale(
      LineItem.calculateTotalAmount(lineItems) +
        (quote?.additionalCharges?.additionalChargeAmount || 0) +
        (quote?.additionalCharges?.additionalChargeTaxAmount || 0) -
        LineItem.getGlobalDiscountAmount(quote)
    );
  };
  static getGlobalDiscountAmount = (
    quote: IQuotation | IQuoteDocument
  ): number => {
    return (
      (Number(quote?.additionalCharges?.globalDiscount?.amount) || 0) +
      (Number(quote?.additionalCharges?.additionalDiscountAmount) || 0) +
      (Number(quote?.additionalCharges?.additionalDiscountTaxAmount) || 0)
    );
  };
  static calculateTotalTaxWithAdditionalTaxes = (
    lineItems: ILineItem[],
    quote: IQuotation | IQuoteDocument
  ) => {
    return roundOffToTenantDecimalScale(
      LineItem.calculateTaxAmount(lineItems) +
        (Number(quote?.additionalCharges?.additionalChargeTaxAmount) || 0) +
        (Number(quote?.additionalCharges?.additionalDiscountTaxAmount) || 0)
    );
  };
  static filterOptionalProducts = (lineItems) => {
    return lineItems?.filter((item) => item.optional?.id !== 2) || [];
  };
}

export interface IQuoteDocument {
  id?: number;
  items?: any;
  subTotal?: number;
  discountAmount?: number;
  sequenceFormat?: any;
  documentSequenceCode?: any;
  taxAmount?: number;
  total?: number;
  contact?: any;
  currency?: any;
  currencyCode?: string;
  contactCode?: string;
  org?: any;
  quoteDate?: any;
  dueDate?: any;
  shipByDate?: any;
  additionalCharges?: IAdditionalCharges;
  memo?: string;
  approvalStatus?: APPROVAL_STATUS;
  isAccountContact?: boolean;
  customField?: any[];
  lineNumber?: number;
  manualMode?: boolean;
  gstType?: GST_TYPE;
  uom?: boolean;
  productGroupId?: any;
  productGroupName?: string;
  productGroupUuid?: string;
  crm3DealId?: string;
  crm3DealOwner?: any;
  crm3DealOwnerIds?: any;
  primary: boolean;
  exchangeRate: number;
  previousExchangeRate?: number;
  priceListId?: any;
  priceListName?: string;
  locked?: boolean;
  multiApprovalDetails?: IMultiApprovalDetails;
  productGroupIndex?: number;
  editingLocked?: boolean;
  editingBy?: number;
  editingAt?: string;
  seqCodeAlreadyDumped?: boolean;
  attachments?: number[];
  attachmentIds?: number[];
  createdBy?: number;
}

export const ProductInitialState = {
  offeringType: "GOODS",
  type: "TRACKED",
  name: "",
  productNumberFormat: "Manual",
  defaultPurchaseTax: false,
  defaultSalesTax: false,
  barcode: null,
  description: "",
  purchaseAccountCode: "",
  purchaseTaxCode: "",
  purchasePrice: 0,
  salesAccountCode: "",
  salesPrice: 0,
  salesTaxCode: "",
  salesReturnAccountCode: "",
  purchaseReturnAccountCode: "",
  stockUom: 2,
  advancedTracking: "NONE",
  inventory: {
    openingQuantity: 0,
    availableQuantity: 0,
    openingValuation: 0,
    reservedQuantity: 0,
    costOfGoodsSoldAccountCode: "",
    inventoryAccountCode: "",
    inventoryAccountName: null,
    stockAdjustmentAccountCode: "",
    warehouseCode: ""
  },
  whtApplicable: false,
  exciseApplicable: false,
  exciseRate: 0,
  exciseRateIndo: null,
  exciseType: "FLAT",
  multipleUomSchema: false,
  images: [],
  incomingQty: 0,
  outgoingQty: 0,
  manufacturingCostAccountCode: "",
  exemptedMalaysia: true,
  attributes: [],
  variantAttributes: {},
  isVariant: false,
  hasVariants: false,
  reorderLevelReached: false,
  variantCount: null,
  taxPreference: true,
  cessRuleDescription: null,
  itcAdjustment: "NA",
  glAccountCode: "NA",
  tdsApplicableIndia: false,
  reorderQuantity: undefined,
  reorderVendorCode: "",
  tdsNatureOfPaymentIndia: "",
  taxable: true
};
