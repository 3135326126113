import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import {
  DKLabel,
  DKIcons,
  DKIcon,
  showAlert,
  DKSpinner,
  DKSegmentControl
} from "deskera-ui-library";
import Utility, { getCapitalized } from "../../utility/Utility";
import NoRecordFound from "./NoRecordFound";
import {
  EXTENSIONS_FILE_ALLOWED_UPLOAD,
  MAX_FILE_SIZE,
  openFileBrowser
} from "../../services/common/file";

import ic_doc from "../../assets/icons/ic_doc.png";
import ic_img from "../../assets/icons/ic_img.png";
import ic_pdf from "../../assets/icons/ic_pdf.png";
import ic_ppt from "../../assets/icons/ic_ppt.png";
import ic_xlsx from "../../assets/icons/ic_xlsx.png";
import ic_upload_filled from "../../assets/icons/ic_upload_filled.png";
import Popup from "./Popup";
import ApiConstants from "../../constants/ApiConstants";
import { USER_ACTION_TYPES } from "../../constants/Permission";

export type Attachment = {
  path: string;
  title: string;
  type: string;
};
export interface IAttachmentListProps {
  readOnly?: boolean;
  attachments: Attachment[];
  isUploading?: boolean;
  permissions: any;
  classForEmptyAttachment?: string;
  allowMultipleUpload?: boolean;
  onAttachmentSelected: (files: File[]) => void;
  onAttachmentDelete: (attachment: Attachment) => void;
}
const styles: { [key: string]: React.CSSProperties } = {
  attachmentWrapper: {
    width: 200,
    height: 200,
    overflow: "hidden",
    gap: 8
  },
  mainWrapper: {
    gap: 15
  },
  attachment: {
    maxWidth: 200,
    height: 160,
    overflow: "hidden"
  },
  attachmentDefaultIcon: {
    width: 80,
    height: 80
  },
  attachmentTitle: {
    height: 24,
    maxWidth: "100%"
  },
  attachmentTitles: {
    width: 200,
    height: 170,
    overflow: "hidden",
    top: 0,
    background: "rgba(255,255,255,0.5)",
    wordBreak: "break-word",
    gap: 16
  },
  ellipsis: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden"
  }
};

const PLAIN_DOC_EXTENSIONS = [
  "text/plain",
  "application/txt",
  "text/csv",
  "application/vnd.ms-excel"
];

const FILE_EXTENSION_GROUP = {
  ALL: null,
  IMAGE: [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/svg",
    "image/svg+xml"
  ],
  XLS: [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "text/csv"
  ],
  DOC: [
    "text/plain",
    "application/txt",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  ],
  PDF: ["application/pdf"],
  PPT: [
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation"
  ]
};

export default function AttachmentList(props: IAttachmentListProps) {
  const [filterBy, setFilterBy] = useState(
    Object.keys(FILE_EXTENSION_GROUP)[0]
  );
  const [filteredAttachments, setFilteredAttachments] = useState(
    props.attachments
  );
  const fileDropContainerRef = useRef(null);
  const newFileRef = useRef(null);

  useEffect(() => {
    setFilteredAttachmentmentsToDisplay();
  }, [filterBy, props.attachments]);

  useEffect(() => {
    if (
      !Utility.isEmptyObject(FILE_EXTENSION_GROUP[filterBy]) &&
      newFileRef.current?.some(
        (file: File) => !FILE_EXTENSION_GROUP[filterBy].includes(file.type)
      )
    ) {
      setFilterBy(Object.keys(FILE_EXTENSION_GROUP)[0]);
    }

    newFileRef.current = null;
  }, [props.isUploading]);

  const setFilteredAttachmentmentsToDisplay = () => {
    const fileExtFilters = FILE_EXTENSION_GROUP[filterBy] || [];
    let filteredRes = props.attachments || [];
    filteredRes = fileExtFilters.length
      ? filteredRes.filter((attachment: Attachment) =>
          fileExtFilters.includes(attachment.type)
        )
      : [...filteredRes];
    /* showing recent attachment on top */
    filteredRes.reverse();
    setFilteredAttachments(filteredRes);
  };
  const renderFile = (file: Attachment) => {
    if (FILE_EXTENSION_GROUP.PDF.includes(file.type)) {
      return (
        <FileWrapper
          file={{ ...file, path: Utility.getFileIcon(file.title) }}
          style={styles.attachmentDefaultIcon}
          type="image"
        />
      );
    } else if (FILE_EXTENSION_GROUP.DOC.includes(file.type)) {
      return (
        <FileWrapper
          file={{ ...file, path: Utility.getFileIcon(file.title) }}
          style={styles.attachmentDefaultIcon}
          type="image"
        />
      );
    } else if (FILE_EXTENSION_GROUP.IMAGE.includes(file.type)) {
      return <FileWrapper file={file} type="image" />;
    } else {
      return (
        <FileWrapper
          file={{ ...file, path: Utility.getFileIcon(file.title) }}
          type="image"
          style={styles.attachmentDefaultIcon}
          className="unidentified-file"
        />
      );
    }
  };

  const renderAttachment = (attachment: Attachment, index: number) => {
    let pathKey = "";
    try {
      pathKey = attachment.path.split("/").at(-1);
    } catch (err) {
      pathKey = `${index}_${attachment.title}`;
    }

    return (
      <div
        className={`attachment-wrapper position-relative border-m border-radius-m p-s border-box column align-items-center justify-content-center`}
        style={styles.attachmentWrapper}
        key={pathKey}
      >
        {renderFile(attachment)}
        {getAttachmentTitleWithIcon(attachment)}
        <div
          style={styles.attachmentTitles}
          className={
            "attachment-title position-absolute align-items-center fs-r fw-b p-l row justify-content-center cursor-hand"
          }
          onClick={(e) => attachmentAction(e, attachment, "view")}
        >
          <div onClick={(e) => attachmentAction(e, attachment, "view")}>
            <DKIcon
              className="ic-s-2 bg-white p-xs border-radius-s"
              src={DKIcons.ic_view}
            />
          </div>
          {props.permissions[USER_ACTION_TYPES.ATTACHMENT_DOWNLOAD] && (
            <DKIcon
              className="ic-s-2 bg-white p-xs border-radius-s position-absolute"
              src={DKIcons.ic_download}
              style={{
                top: 8,
                right: 44
              }}
              onClick={(e) => attachmentAction(e, attachment, "download")}
            />
          )}
          {!props.readOnly &&
            props.permissions[USER_ACTION_TYPES.ATTACHMENT_DELETE] && (
              <DKIcon
                className="ic-s-2 bg-white p-xs border-radius-s position-absolute"
                src={DKIcons.ic_delete}
                style={{
                  top: 8,
                  right: 8
                }}
                onClick={(e) => attachmentAction(e, attachment, "delete")}
              />
            )}
        </div>
      </div>
    );
  };
  const renderEmptyState = () => (
    <NoRecordFound
      title={`No attachments found`}
      subTitle={
        props.readOnly
          ? "Looks like you don't have permission to add attachments, please contact your organization owner."
          : "Upload files or drag and drop files here"
      }
      isButton={!props.readOnly}
      buttonTitle={`+ Add Attachment`}
      onClick={browseAttachment}
      className={
        props.classForEmptyAttachment ? props.classForEmptyAttachment : ""
      }
    />
  );
  const attachmentAction = (
    e: any,
    attachment: Attachment,
    action: "view" | "download" | "delete"
  ) => {
    e?.stopPropagation?.();

    if (action === "view") {
      /* preview only available for image & pdf */
      getAttachmentPreviewInPopup(
        attachment,
        {
          onDelete: () => onDeleteAttachment(attachment)
        },
        props.permissions
      );
    } else if (action === "delete") {
      onDeleteAttachment(attachment);
    } else {
      Utility.openInNewTab(attachment.path);
    }
  };
  const onDeleteAttachment = (attachment: Attachment) => {
    const actions = [
      {
        title: "Cancel",
        className: "bg-gray1 border-m",
        onClick: () => {}
      },
      {
        title: "Delete",
        className: "bg-red text-white ml-r",
        onClick: () => {
          props.onAttachmentDelete(attachment);
        }
      }
    ];

    showAlert(
      "Delete Attachment?",
      "Deleted attachment can not be recovered later.",
      actions
    );
  };
  const onNewAttachmentSelected = (files: File[]) => {
    if (!files?.[0]) return;

    files = Array.from(files);
    newFileRef.current = files;
    props.onAttachmentSelected(files);
  };
  const browseAttachment = () => {
    if (props.isUploading) return;
    openFileBrowser(onNewAttachmentSelected, {
      accept: Object.values(EXTENSIONS_FILE_ALLOWED_UPLOAD).join(","),
      multiple: props.allowMultipleUpload
    });
  };

  /* File upload through drag & drop */
  const onFileDrop = (event: any) => {
    event.stopPropagation();
    event.preventDefault();

    fileDropContainerRef.current.style.opacity = 1;

    const files = event.dataTransfer.files;
    let fileList = filterFilesBySizeAndExtension(files);
    if (fileList?.length > 0) {
      onNewAttachmentSelected(fileList);
    }
  };

  const filterFilesBySizeAndExtension = (fileList: FileList) => {
    let fileExtNames = [];
    let fileSizeNames = [];
    // Define allowed extensions
    const allowedExtensions = Object.values(EXTENSIONS_FILE_ALLOWED_UPLOAD);
    // Filter files
    const filteredFiles = Array.from(fileList).filter((file: File, index) => {
      let newFile = file;
      const fileSizeValid = newFile.size <= MAX_FILE_SIZE;

      // Get the file extension and check if it's allowed
      const fileExtension = newFile.name.split(".").pop().toLowerCase();
      const fileExtensionValid = allowedExtensions.includes(
        "." + fileExtension
      );

      if (!fileSizeValid) {
        fileSizeNames?.push(newFile.name);
      }
      if (!fileExtensionValid) {
        fileExtNames?.push(newFile.name);
      }

      return fileSizeValid && fileExtensionValid;
    });

    if (fileExtNames?.length > 0 || fileSizeNames?.length > 0) {
      showAlert(
        "Error",
        `${fileSizeNames?.length > 0 ? "File size should not be more than 10 MB. Please optimize " + fileSizeNames?.toString() : ""} ${fileExtNames?.length > 0 ? " Selected file format is not supported for this attachments " + fileExtNames?.toString() : ""}`
      );
    }

    return filteredFiles;
  };

  const onDragEnter = (event: any) => {
    event.stopPropagation();
    event.preventDefault();

    fileDropContainerRef.current.style.opacity = 0.6;
  };

  const onDragEnd = (event: any) => {
    event.stopPropagation();
    event.preventDefault();

    fileDropContainerRef.current.style.opacity = 1;
  };

  const onDragOver = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const getAttachmentFilters = () => {
    if (Utility.isEmptyObject(props.attachments)) return;

    const fileExtensionGroups = Object.keys(FILE_EXTENSION_GROUP);

    return (
      <div className="row width-auto" style={{ margin: "12px auto 32px" }}>
        <DKSegmentControl
          width={300}
          segments={fileExtensionGroups.map((fileGroup) =>
            getCapitalized(fileGroup.toLowerCase())
          )}
          backgroundColor="bg-gray1"
          selectedColor=""
          barColor="bg-white"
          selectedIndex={fileExtensionGroups.indexOf(filterBy)}
          onSelect={(index: number) => setFilterBy(fileExtensionGroups[index])}
        />
      </div>
    );
  };

  return (
    <div
      className={
        `column parent-width flex-1 margin-empty-mobile p-l bg-white position-relative ` +
        (Utility.isEmptyObject(props.attachments) &&
          "align-items-center justify-content-center")
      }
      ref={fileDropContainerRef}
      onDragEnter={props.readOnly ? null : onDragEnter}
      onDragOver={props.readOnly ? null : onDragOver}
      onDragEnd={props.readOnly ? null : onDragEnd}
      onDrop={props.readOnly ? null : onFileDrop}
    >
      {getAttachmentFilters()}
      {!Utility.isEmptyObject(filteredAttachments) && (
        <div
          className={
            "row align-items-start flex-wrap justify-content-center-mobile"
          }
          style={styles.mainWrapper}
        >
          {!props.readOnly &&
            props.permissions[USER_ACTION_TYPES.ATTACHMENT_UPLOAD] && (
              <div
                className="column align-items-center justify-content-center fs-l bg-gray1 border-m cursor-hand border-radius-m p-r"
                onClick={browseAttachment}
                style={styles.attachmentWrapper}
              >
                <DKIcon src={ic_upload_filled} className="ic-xl" />
                <DKLabel
                  text={`${props.isUploading ? "Uploading..." : "Upload files"}`}
                />
              </div>
            )}
          {filteredAttachments.map(renderAttachment)}
        </div>
      )}
      {Utility.isEmptyObject(filteredAttachments) && renderEmptyState()}
    </div>
  );
}

const FileWrapper = ({
  file,
  type,
  className = "",
  style = {},
  needLoader = true,
  isDarkTheme = false
}) => {
  const [isLoading, setIsLoading] = React.useState(true);

  const needPngBackground = isDarkTheme && file.type === "image/png";
  return (
    <>
      {isLoading && needLoader && (
        <div className="row width-auto justify-content-center position-absolute">
          <DKSpinner isWhite={isDarkTheme} />
        </div>
      )}
      {type === "image" ? (
        <img
          style={{
            ...styles.attachment,
            background: needPngBackground
              ? "url(https://i.ibb.co/F7ys5Pg/png-Background.png)"
              : "transparent",
            padding: needPngBackground ? 4 : 0,
            ...style
          }}
          src={file.path}
          alt={file.title}
          className={`paren-size mt-auto ${className || ""}`}
          onLoad={() => setIsLoading(false)}
          onError={() => setIsLoading(false)}
        />
      ) : (
        <embed
          style={{
            ...styles.attachment,
            ...style
          }}
          src={file.path}
          onLoad={() => setIsLoading(false)}
          onError={() => setIsLoading(false)}
          className={`paren-size mt-auto ${className || ""}`}
          title={file.title}
        ></embed>
      )}
    </>
  );
};

const getAttachmentTitleWithIcon = (
  attachment: Attachment,
  isDarkTheme?: boolean
) => {
  let icon: string;
  if (FILE_EXTENSION_GROUP.PDF.includes(attachment.type)) {
    icon = ic_pdf;
  } else if (FILE_EXTENSION_GROUP.DOC.includes(attachment.type)) {
    icon = ic_doc;
  } else if (FILE_EXTENSION_GROUP.PPT.includes(attachment.type)) {
    icon = ic_ppt;
  } else if (FILE_EXTENSION_GROUP.XLS.includes(attachment.type)) {
    icon = ic_xlsx;
  } else {
    icon = ic_img;
  }

  return (
    <div
      className="row align-items-center mt-auto"
      style={styles.attachmentTitle}
    >
      <DKIcon src={icon} className="ic-xs-2" />
      <DKLabel
        text={attachment.title}
        className={"ml-r pb-xs " + (isDarkTheme ? " fs-l text-white " : "")}
        style={styles.ellipsis}
      />
    </div>
  );
};

export const AttachmentPreview = ({
  attachment,
  maxWidth = "90vw",
  maxHeight = "100vh",
  needLoader = true,
  onClose = null,
  onDelete = null,
  headerIconButton = null,
  isPopup = false,
  darkTheme = false,
  permissions = {}
}) => {
  if (Utility.isEmptyObject(attachment)) return null;

  function getDocumentPreviewPath() {
    let documentPreviewPath: string;

    if (
      [...FILE_EXTENSION_GROUP.IMAGE, ...FILE_EXTENSION_GROUP.PDF].includes(
        attachment.type
      )
    ) {
      documentPreviewPath = attachment.path;
    } else if ([...PLAIN_DOC_EXTENSIONS].includes(attachment.type)) {
      /* Showing: plain docs in google docs web viewer & others in office web viewer
        Ref: https://gist.github.com/tzmartin/1cf85dc3d975f94cfddc04bc0dd399be#gistcomment-2310091 */
      documentPreviewPath = ApiConstants.URL.FILES.GOOGLE_VIEWER(
        attachment.path
      );
    } else {
      /* Showing: xls, xlsx, doc, docx, ppt, pptx in Office web viewer */
      documentPreviewPath = ApiConstants.URL.FILES.OFFICE_VIEWER(
        attachment.path
      );
    }

    return documentPreviewPath;
  }

  const onClickBackground = (e) => {
    if (!e.target.classList.contains("attachment-fullscreen-background"))
      return;
    onClose?.();
  };

  const isImage = FILE_EXTENSION_GROUP.IMAGE.includes(attachment.type);

  return (
    <div
      className={
        "column parent-size align-items-center flex-1 " +
        (isPopup ? "" : " mt-r ")
      }
    >
      <div
        className={
          "row justify-content-between " + (isPopup ? " p-r " : " mb-r ")
        }
        style={{
          width: isPopup ? "100%" : maxWidth,
          maxWidth: isPopup ? "100%" : maxWidth,
          gap: isPopup ? 24 : 8,
          background: isPopup && darkTheme ? "rgb(0, 0, 0, 0.9)" : "transparent"
        }}
      >
        <div
          className="row width-auto text-ellipsis"
          style={{
            WebkitLineClamp: 1
          }}
        >
          {getAttachmentTitleWithIcon(attachment, darkTheme)}
        </div>
        {headerIconButton ? (
          <DKIcon
            src={headerIconButton.icon}
            className={`ic-s ml-auto cursor-hand`}
            onClick={headerIconButton.onClick}
          />
        ) : null}
        {permissions[USER_ACTION_TYPES.ATTACHMENT_DOWNLOAD] && (
          <DKIcon
            src={darkTheme ? DKIcons.white.ic_download : DKIcons.ic_download}
            className={`${isPopup ? "ic-s-2" : "ic-s"} ${
              headerIconButton ? "" : "ml-auto"
            } cursor-hand`}
            onClick={(e) => {
              e.stopPropagation?.();
              Utility.openInNewTab(attachment.path);
            }}
          />
        )}
        {onDelete && permissions[USER_ACTION_TYPES.ATTACHMENT_DELETE] ? (
          <DKIcon
            src={darkTheme ? DKIcons.white.ic_delete : DKIcons.ic_delete}
            className={`${isPopup ? "ic-s-2" : "ic-s"} cursor-hand`}
            onClick={onDelete}
          />
        ) : null}
        {onClose ? (
          <DKIcon
            src={darkTheme ? DKIcons.white.ic_close : DKIcons.ic_close}
            className={`${isPopup ? "ic-s-2" : "ic-s"} cursor-hand`}
            onClick={onClose}
          />
        ) : null}
      </div>
      <div
        className="column flex-1 parent-width align-items-center justify-content-center attachment-fullscreen-background"
        onClick={onClickBackground}
      >
        <FileWrapper
          file={{
            ...attachment,
            path: getDocumentPreviewPath()
          }}
          style={{
            objectFit: "contain",
            width: isImage || !isPopup ? "auto" : "40vw",
            height: isImage || !isPopup ? "auto" : maxHeight,
            maxHeight: `calc(${maxHeight} - 90px)`,
            maxWidth: maxWidth
          }}
          className="mb-auto"
          type={isImage ? "image" : "embed"}
          needLoader={needLoader}
          isDarkTheme={darkTheme}
        />
      </div>
    </div>
  );
};

export const getAttachmentPreviewInPopup = (
  attachment: Attachment,
  handlers?: {
    onDelete?: () => void;
    onClose?: () => void;
  },
  permissions?
) => {
  const id = `attachment-preview-popup-${new Date().getTime()}`;
  let div = document.createElement("div");
  div.className = "attachment-preview-popup app-font";
  div.setAttribute("id", id);

  const handleOnClose = () => {
    handlers?.onClose?.();
    const attachmentPopupElement = document.getElementById(id);
    ReactDOM.unmountComponentAtNode(attachmentPopupElement);
    attachmentPopupElement?.remove();
  };

  ReactDOM.render(
    <Popup
      popupWindowStyles={{
        overflowY: "hidden",
        width: "100vw",
        height: "100vh",
        maxWidth: "100vw",
        maxHeight: "100vh",
        background: "rgba(0, 0, 0, 0.85)",
        padding: 0,
        borderRadius: 0,
        top: 0,
        left: 0,
        transform: "translate(0, 0)"
      }}
    >
      <AttachmentPreview
        attachment={attachment}
        permissions={permissions}
        isPopup={true}
        onClose={handleOnClose}
        onDelete={
          handlers?.onDelete
            ? () => {
                handleOnClose();
                handlers.onDelete();
              }
            : null
        }
        darkTheme={true}
      />
    </Popup>,
    document.body.appendChild(div)
  );
};
